<template>
  <result title="胃健康风险评估结果" :content="content" :dist="dist" :fot="fot" :id="id" :img-show="imgShow"></result>
</template>

<script>
export default {
  name:'',
  data(){
    return {
      id: '',
      content: '',
      dist: '',
      fot: '',
      imgShow: true
    }
  },
  mounted(){
    this.id = this.$route.query.id;
    var isCheck = this.$route.query.isCheck;
    var imgShow = this.$route.query.img;
    if(imgShow == 'true'){
      this.imgShow = true;
    }else{
      this.imgShow = false;
    }
    if(isCheck == 'true'){
      this.content = '您<span style="color: #FF6054">属于</span>我国胃癌筛查目标人群';
      this.dist = '但也要注意日常胃部健康监测，定期体检';
    }else if(isCheck == 'false'){
      this.content = '您<span style="color: #1ED6A8">不属于</span>我国胃癌筛查目标人群';
      this.dist = '建议就医，遵医嘱进行胃镜检测';
    }else{
      var str = isCheck == '低风险' ? '暂不推荐胃镜检查' : isCheck == '中风险' ? '推荐胃镜检查' : '强烈推荐胃镜检查'
      this.content = `依据您填写的信息${str}`;
      this.dist = '请及时就医并遵医嘱进行胃镜检查';
      this.fot = '自主研发，结果仅供参考，以医生诊断结果为准'
    }
  },
  methods:{

  }
}
</script>

<style scoped lang="scss">
  
</style>
